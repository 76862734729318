import React from 'react';

import appStoreImg from '../StoreLink/App_Store_Badge.png';
import playStoreImg from '../StoreLink/Play_Store_Badge.png';
import promoCardImg from '../PromoCard/BetterDrivingTheoryDiscountsCard.png';

// if on a mobile device
// then use our long link: https://betterdrivingtheory.com/applinks/?link=https://betterdrivingtheory.com/discount?code%3DLUNA&apn=dev.seventytwodays.drivingtheory&isi=1608720630&ibi=dev.seventytwodays.drivingtheory

// if on a desktop device then use a QR code

const appStoreLink =
  'https://apps.apple.com/gb/app/better-driving-theory/id1608720630';
const playStoreLink =
  'https://play.google.com/store/apps/details?id=dev.seventytwodays.drivingtheory';

export const DiscountCode = ({ discountCode }: { discountCode?: string }) => {
  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <div style={{}}>
        <h1>Better Driving Theory</h1>
        {!!discountCode && (
          <>
            <h2>Discount Code</h2>
            <h3>{discountCode}</h3>
          </>
        )}

        <img
          src={promoCardImg}
          alt="Better Driving Theory"
          style={{
            width: '80%',
            maxWidth: '400px',
            borderRadius: 20,
            marginTop: 10,
          }}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          marginTop: '40px',
        }}
      >
        <div style={{ maxWidth: 300, marginRight: '10px' }}>
          <a href={appStoreLink} target="_blank" rel="noreferrer">
            <img
              src={appStoreImg}
              alt="Apple App Store"
              style={{ width: '100%' }}
            />
          </a>
        </div>
        <div style={{ maxWidth: 300, marginLeft: '10px' }}>
          <a href={playStoreLink} target="_blank" rel="noreferrer">
            <img
              src={playStoreImg}
              alt="Google Play Store"
              style={{ width: '100%' }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};
