import React from 'react';

import { Layout } from '~/components/layout';
import { DiscountCode } from '~/components/page-components/DiscountCode';

const DiscountPage = () => {
  const [discountCode, setDiscountCode] = React.useState('');
  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    if (code) {
      setDiscountCode(code);
    }
  }, []);
  return (
    <Layout title="Discount Code" standalonePage>
      <DiscountCode discountCode={discountCode} />
    </Layout>
  );
};

export default DiscountPage;
